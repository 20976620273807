<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Contrats"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>

            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="factureContratSimples"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(proprietaire.firstname)="data">
            <div>

              {{ data.item.proprietaire.firstname }} {{ data.item.locataire.lastname }}

            </div>
          </template>
          <template #cell(type_contrat.title)="data">
            <div>

              {{ data.item.type_contrat.title }}

            </div>
          </template>
          <template #cell(payment_status)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${statusContratVariant(data.item.payment_status)}`"
              >
                {{ data.item.payment_status }}

              </b-badge>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Payer la facture'"
              variant="gradient-secondary"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="checkOut(data.item)"
            >
              <b-img
                :style="{ height: '25px' }"
                :src="require('@/assets/images/carte-de-credit.png')"
              />
            </b-button>

          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="factureContratSimples.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import {
  VBTooltip, BRow, BCol, BImg, BCard, BTable, BFormGroup, BFormSelect, BBadge, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput, BForm, BSpinner, VBModal,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []

    const {
      loader,
      getFactureByProprietaireId,
      factureContratSimples,
      payerFraisCopropriete,
    } = useProprietes()
    const user = JSON.parse(store.state.authStore.user)
    const { SITE_URL } = $themeConfig.app

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'motif', label: 'Motif', sortable: true },
      { key: 'montant', label: 'Montant', sortable: true },
      { key: 'date_normal_paiement', label: 'Date de payement', sortable: true },
      { key: 'payment_status', label: 'Statut de la facture', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      name: '',
      societe_id: '',
      modele_economique_id: '',
      description: '',
    })
    /* eslint-disable global-require */

    const myModal = ref('')

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    const statusContratVariant = status => {
      if (status === 'En attente') return 'primary'
      if (status === 'completed') return 'success'
      if (status === 'Actif') return 'danger'
      return 'primary'
    }

    onMounted(async () => {
      await getFactureByProprietaireId(user.id)
      // Set the initial number of items
      totalRows.value = factureContratSimples.value.length
    })

    const coproprieteFormRules = ref('')

    const dataCheckOut = reactive({
      amount: null,
      paiement_contribution_id: '',
    })

    const checkOut = async item => {
      dataCheckOut.redirectUri = window.location.href
      dataCheckOut.amount = item.montant
      dataCheckOut.paiement_contribution_id = item.id
      await payerFraisCopropriete({ ...dataCheckOut })
      console.log(item)
    }

    return {
      factureContratSimples,

      sortOptions,
      statusContratVariant,
      loader,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      myModal,
      filterOn,
      fields,
      onFiltered,
      form,
      errors,
      required,
      avatarText,
      coproprieteFormRules,
      SITE_URL,
      checkOut,

    }
  },

}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
